<template>
  <ContractNotFound v-if="contractNotFound" />
  <div
    v-else
    id="sign"
    v-loading="contract == null || hotel == null || loading"
  >
    <div class="signing" :class="{ mobile: !$full }" v-if="!isSigned">
      <SideInfo :contract="contract" :hotel="hotel" v-if="contract && hotel" />

      <div class="main" v-if="contract">
        <el-steps
          direction="vertical"
          :active="guests.length"
          :class="{
            hideSteps: !canEditGuests,
          }"
        >
          <el-step>
            <template v-slot:title>
              <Form
                :contract="contract"
                ref="main-form"
                class="collapsable-form"
                :class="{
                  collapsed: currentlyFillingContractId != 0 && canEditGuests,
                }"
                :formId="0"
              />
              <div
                v-if="currentlyFillingContractId != 0 && canEditGuests"
                @click="currentlyFillingContractId = 0"
              >
                <div>{{ getFullNameByContract() }}</div>
                <el-link type="primary">{{ $t('settings.edit') }}</el-link>
              </div>
            </template>
          </el-step>

          <el-step
            v-for="id in canEditGuests ? contract.guestsQty - 1 : 0"
            :key="id"
          >
            <template v-slot:title v-if="guests.length >= id">
              <GuestForm
                :contract="guests[id - 1]"
                :mainContract="contract"
                ref="guest-form"
                class="collapsable-form"
                :class="{ collapsed: currentlyFillingContractId != id }"
                :formId="id"
              />
              <div
                v-if="currentlyFillingContractId != id"
                @click="currentlyFillingContractId = id"
              >
                <div>{{ getFullNameByContract(guests[id - 1]) }}</div>
                <el-link type="primary">{{ $t('settings.edit') }}</el-link>
              </div>
            </template>

            <template v-slot:title v-else-if="guests.length == id - 1">
              <el-button type="success" icon="el-icon-plus" @click="addGuest">{{
                $t('contract.addGuest')
              }}</el-button>
            </template>

            <template v-slot:title v-else>{{
              $t('contract.guestInfo', [id + 1])
            }}</template>
          </el-step>
        </el-steps>

        <div class="send-form">
          <AgreeForm :fullName="getFullNameByContract()" ref="agree-form" />
          <el-button
            type="primary"
            @click="sendContract"
            :disabled="canEditGuests && contract.guestsQty != guests.length + 1"
            >{{ $t('settings.next') }}</el-button
          >
        </div>

        <a class="made-by" href="https://partner.cncrg.org" target="_blank">
          <div class="label">{{ $t('contract.madeBy') }}</div>
          <img
            :src="$store.isSystemDarkTheme ? '/Logo.png' : '/LogoBlack.png'"
          />
        </a>
      </div>
    </div>
    <div class="signed" v-else>
      <div class="gradient" />
      <SignedInfo
        v-if="contract && hotel"
        :contract="contract"
        :stay="stay"
        :hotel="hotel"
        @downloadContract="downloadContract"
        @downloadInstruction="downloadInstruction"
      />
    </div>
  </div>
</template>

<script>
import SignedInfo from '@/components/ContractSign/SignedInfo'
import SideInfo from '@/components/ContractSign/SideInfo'
import Form from '@/components/ContractSign/Form'
import GuestForm from '@/components/ContractSign/GuestForm'
import AgreeForm from '@/components/ContractSign/AgreeForm'
import ContractNotFound from '@/components/ContractSign/ContractNotFound'
import { parsePhoneNumber } from 'libphonenumber-js'

import { auto as followSystemColorScheme, setFetchMethod } from 'darkreader'
import { formatDateDMY } from '../lib/formatDate'
setFetchMethod(window.fetch)

const supportedCountries = [
  'RU',
  'BY',
  'AZ',
  'AM',
  'DE',
  'GE',
  'ES',
  'KZ',
  'RS',
  'TJ',
  'TM',
  'UZ',
]

export default {
  components: {
    SideInfo,
    Form,
    SignedInfo,
    GuestForm,
    AgreeForm,
    ContractNotFound,
  },
  data() {
    return {
      stay: null,
      contract: null,
      hotel: null,
      guests: [],
      loading: false,
      currentlyFillingContractId: 0,
      contractNotFound: false,
    }
  },
  created() {
    if (!this.token) {
      this.contractNotFound = true
      return
    }

    this.fetchStay()
      .then(async () => {
        this.fillContractFromStay()
        await this.fetchHotel()
      })
      .catch(() => {
        this.contractNotFound = true
      })
  },
  mounted() {
    followSystemColorScheme(true)
  },
  beforeDestroy() {
    followSystemColorScheme(false)
  },
  methods: {
    async fetchStay() {
      const { data } = await this.$defaultApi.get('pms/user-stays/contracted', {
        headers: { token: this.token },
        skip401: true,
      })
      this.stay = data.data
    },
    async fetchHotel() {
      const { data } = await this.$api.get('hotels/' + this.stay.propertyId)
      this.hotel = data.data
    },
    checkFormsHasInvalid(props = { ignoreAgree: false }) {
      let forms = [this.$refs['main-form']]
      if (!props.ignoreAgree) forms.push(this.$refs['agree-form'])
      if (this.$refs['guest-form']) {
        forms = forms.concat(this.$refs['guest-form'])
      }

      let hasInvalid = false
      for (const form of forms) {
        form.$children
          .find(f => f.validate)
          .validate(valid => {
            if (!valid) {
              if (hasInvalid) return
              hasInvalid = true
              this.currentlyFillingContractId = form.$attrs.formId
            }
          })
      }
      return hasInvalid
    },
    async sendContract() {
      if (this.checkFormsHasInvalid()) return

      const data = {
        ...this.contract,

        phone: this.phoneWithoutMask,
        birthDate: this.contract.birthDate.replaceAll('.', '-'),
        passportIssuedDate: this.contract.passportIssuedDate.replaceAll(
          '.',
          '-',
        ),

        guests: this.guests.map(guestContract => ({
          ...guestContract,

          birthDate: guestContract.birthDate.replaceAll('.', '-'),
          documentIssuedDate: guestContract.documentIssuedDate.replaceAll(
            '.',
            '-',
          ),
        })),
      }

      this.loading = true
      try {
        await this.$defaultApi.post('pms/user-stays/contracted', data, {
          headers: { token: this.token },
        })

        await this.fetchStay()
        if (this.isSigned) {
          this.loading = false
          return
        }

        const contractPreview = (
          await this.$defaultApi.get('pms/user-stays/contracted/preview', {
            headers: { token: this.token },
          })
        ).data.data
        this.loading = false

        this.$events.emit('show-contract', contractPreview, () => {
          this.$events.emit('do-login-popup', {
            callback: this.signContract,
            dontSendCustomerId: true,
            phoneOnlyCountries: supportedCountries,
            phone: data.phone,
            texts: {
              enterTitle: 'contract.enterPhone',
              enterDescription: 'contract.authDescriptionSign',
            },
          })
        })
      } catch (err) {
        this.$events.emit(
          'show-alert',
          this.$t('errors.error'),
          String(err.response?.data?.message || err.message),
        )
      }
      this.loading = false
    },
    async signContract() {
      this.loading = true
      try {
        await this.$defaultApi.post(
          'pms/user-stays/contracted/sign',
          {
            token: this.token,
          },
          { headers: { token: localStorage.token } },
        )
        await this.fetchStay()
      } catch (err) {
        this.$events.emit(
          'show-alert',
          this.$t('errors.error'),
          String(err.response?.data?.message || err.message),
        )
      }
      this.loading = false
    },
    addGuest() {
      if (this.checkFormsHasInvalid({ ignoreAgree: true })) return
      this.guests.push({
        lastName: '',
        firstName: '',
        middleName: '',
        birthDate: null,
        birthPlace: '',
        address: '',
        postcode: null,
        city: null,
        gender: 0,
        isChild: false,
        documentType: 'passport',
        documentSeries: '',
        documentNumber: '',
        documentDivision: '',
        documentIssuedBy: '',
        documentIssuedDate: null,
        citizenship: this.contract.citizenship || 'ru',
      })
      this.currentlyFillingContractId = this.guests.length
    },
    downloadContract() {
      const apiUrl = process.env.VUE_APP_API_URL
      const stayId = this.stay.customer.stayId
      const secureKey = this.stay.secureKey
      window.open(
        `${apiUrl}/pms/user-stays/contracted/download/${stayId}?token=${secureKey}&locale=${window.userLocale}`,
      )
    },
    downloadInstruction() {
      const apiUrl = process.env.VUE_APP_API_URL
      const stayId = this.stay.customer.stayId
      const secureKey = this.stay.secureKey
      window.open(
        `${apiUrl}/pms/user-stays/contracted/download/${stayId}/instruction?token=${secureKey}&locale=${window.userLocale}`,
      )
    },
    fillContractFromStay() {
      const phone = this.stay.customer.guestData.phones[0] ?? ''

      let citizenship = 'ru'
      if (phone)
        try {
          const countryCode = parsePhoneNumber(phone, 'RU').country
          if (supportedCountries.includes(countryCode)) {
            citizenship = countryCode.toLowerCase()
          } else {
            console.warn(
              `Autodetected country "${countryCode.toLowerCase()}" for phone number "${phone}" is not supported`,
            )
          }
        } catch (e) {
          console.warn(`Can't parse phone number "${phone}"`, e)
        }

      this.contract = {
        id: this.stay.bookingNo,
        firstName: this.stay.customer.guestData.firstName,
        lastName: this.stay.customer.guestData.lastName,
        middleName: this.stay.customer.guestData.middleName || '',
        guestsQty: this.stay.guestsQty,
        dateIn: new Date(this.stay.checkInTime),
        dateOut: new Date(this.stay.checkOutTime),
        birthDate: this.stay.customer.guestData.birthDate
          ? formatDateDMY(this.stay.customer.guestData.birthDate)
          : null,
        birthPlace: this.stay.customer.guestData.birthPlace || '',
        address: '',
        postcode: '',
        city: '',
        email: this.stay.customer.guestData.emails[0],
        phone: phone,
        isPhoneDisabled: !!this.stay.customer.guestData.phones[0],
        passportSeries: '',
        passportNumber: '',
        passportDivision: '',
        passportIssuedBy: '',
        passportIssuedDate: '',
        citizenship: citizenship,
      }
    },
    getFullNameByContract(contract = this.contract) {
      return [contract.lastName, contract.firstName, contract.middleName]
        .filter(name => name)
        .join(' ')
    },
  },
  computed: {
    token() {
      return this.$route.params.token
    },
    isSigned() {
      return ['SIGNED', 'APPROVED'].includes(this.stay?.contractSigningStatus)
    },
    phoneWithoutMask() {
      if (!this.contract.phone) return this.contract.phone
      const allowedSymbols = '1234567890+'.slice()
      return this.contract.phone
        .split('')
        .filter(symbol => allowedSymbols.includes(symbol))
        .join('')
    },
    canEditGuests() {
      return (
        this.contract.guestsQty > 1 &&
        (this.stay.questionnaireMode || this.stay.fullForm)
      )
    },
  },
}
</script>

<style lang="scss">
#sign {
  > .signing {
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: #f9fafc;

    &.mobile {
      height: initial;
      flex-direction: column;

      > .main {
        overflow: initial;
      }
    }
    > .main {
      flex: 1;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 15px;

      > * {
        max-width: 640px;
        width: 100%;
      }

      .collapsable-form {
        transition: 0.5s all;
        max-height: 1000px;
        &.collapsed {
          max-height: 0px;
          overflow: hidden;
        }
      }

      .el-steps {
        height: initial;
        margin-bottom: 15px;

        &.hideSteps {
          .el-step__head {
            display: none;
          }
          .el-step__main {
            padding-left: 0px;
          }
        }

        .el-step__title {
          color: black;
          font-weight: 400;
          padding-bottom: 25px;

          &.is-wait {
            color: #909399;
          }
        }
      }

      > .send-form {
        display: flex;
        align-items: center;
      }

      > .made-by {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: black;
        text-decoration: none;

        > .label {
          font-size: 11px;
        }

        > img {
          width: 90px;
        }
      }
    }
  }

  > .signed {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-image: url(~@/assets/SignedBg.webp);
    padding: 0 15px;

    > .gradient {
      background: linear-gradient(
        to top,
        #020024,
        rgb(101 101 108 / 90%) 40%,
        rgb(116 120 121 / 65%)
      );
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  > .el-loading-mask {
    position: fixed;
  }
}
</style>
